function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

import { css } from '@emotion/react';
export var containerStyles = true ? {
  name: "1excoa1",
  styles: "position:fixed;top:0px;left:0px;width:100%;height:100%;display:flex;align-items:center"
} : {
  name: "1qrfwwn-containerStyles",
  styles: "position:fixed;top:0px;left:0px;width:100%;height:100%;display:flex;align-items:center;label:containerStyles;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi91c3Ivc3JjL2FwcC9hcHBzL21hcmtldHBsYWNlLWdsb2JhbC9jb21wb25lbnRzL3BhZ2UtbG9hZGVyL3BhZ2UtbG9hZGVyLm1vZHVsZS5zdHlsZS50c3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRStCIiwiZmlsZSI6Ii91c3Ivc3JjL2FwcC9hcHBzL21hcmtldHBsYWNlLWdsb2JhbC9jb21wb25lbnRzL3BhZ2UtbG9hZGVyL3BhZ2UtbG9hZGVyLm1vZHVsZS5zdHlsZS50c3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgeyBjc3MgfSBmcm9tICdAZW1vdGlvbi9yZWFjdCc7XG5cbmV4cG9ydCBjb25zdCBjb250YWluZXJTdHlsZXMgPSBjc3Moe1xuICBwb3NpdGlvbjogJ2ZpeGVkJyxcbiAgdG9wOiAnMHB4JyxcbiAgbGVmdDogJzBweCcsXG4gIHdpZHRoOiAnMTAwJScsXG4gIGhlaWdodDogJzEwMCUnLFxuICBkaXNwbGF5OiAnZmxleCcsXG4gIGFsaWduSXRlbXM6ICdjZW50ZXInLFxufSk7XG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};